
import {Options, Vue} from 'vue-class-component';
import http from "@/api/http";

@Options({
  data() {
    return {
      weiZhi: '',
      id: 0,
      info: {},
      selfInfo: {}
    }
  },
  mounted() {

    http.get("/chang_jian_wen_ti").then(da => {
      if (da.code == 1) {
        this.info = da.data
      }
    })
    this.setId()
  },

  computed: {

    title_name() {
      let title_name = ''


      this.info.join_wen_zhang.forEach(n => {
        if (n.id == this.id) {
          title_name = n.name
        }
      })
      return title_name;


    }
  },
  methods: {
    setId(){
      if (typeof this.$route.params.id != "undefined") {
        this.id = this.$route.params.id
      }
    },
    clickEvent(vo, index) {
      this.weiZhi = index
      this.id = vo.id
      this.$router.push({
        name: 'chang_jian_wen_ti_index_info',
        params: {id: this.id}
      })
    }
  },
  watch:{
    "$route"(){
      this.setId()
    }
  }
})

export default class index extends Vue {

}
